.btn-red {
    background: $mol-red;
    color: #fff;
}

.btn-red-inverse {
    background: #fff;
    color: $mol-red;
}

.btn-green {
    background: $mol-green;
    color: #fff;
}

.btn-green-inverse {
    background: #fff;
    color: $mol-green;
}

.btn-upper {
    text-transform: uppercase;
}

.btn-dropzone {
    display: none;
}
@import "variables";
@import "vendor/bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap";

@import "partials/article-intros";
@import "partials/buttons";
@import "partials/countdown";
@import "partials/footer";
@import "partials/grid";
@import "partials/header";
@import "partials/inputs";
@import "partials/nav";
@import "partials/panels";
@import "partials/posts";
@import "partials/helpers";
@import "partials/dropzone";

body {
    background: $mol-gray url('/images/bg.png') center top no-repeat;
    color: #686867;
}

#main {
    padding: 0 15px;
}

@media (min-width: $screen-md-min) {
    #main {
        width: 960px;
    }    
}

@media (min-width: $screen-lg-min) {
    #main {
        width: 1140px;
    }    
}

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 84px;
}

a.delete, a.red {
  color: $mol-red;
}

.red-bg {
  background: $mol-red !important;
}

.sweet-overlay {
  background: rgba(255,255,255,.5);
}

.dz-remove {
  color: $mol-red !important;
}
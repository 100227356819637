.form-control {
    display: block;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e7e7e7;
    border-radius: 0px;
    box-shadow: 0px -1px #fff, 0px -2px rgba(0,0,0, .4);

    &:focus {
        background: rgba(171,204,88, .08);
        border-color: #e7e7e7;
        box-shadow: 0px -1px #fff, 0px -2px rgba(0,0,0, .4);
    }
}

.has-error {
    & .help-block,
    & .control-label,
    & .radio,
    & .checkbox,
    & .radio-inline,
    & .checkbox-inline,
    &.radio label,
    &.checkbox label,
    &.radio-inline label,
    &.checkbox-inline label,
    & .form-control-feedback {
        color: #ed4339;
    }

    & .help-block {
        font-size: 9px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    & .form-control, & .form-control:focus {
        border: 1px solid #e7e7e7;  
        border-right: 3px solid #ed4339;
        box-shadow: 0px -1px #fff, 0px -2px rgba(0,0,0, .4);
    }
}

.has-success {
    & .help-block,
    & .control-label,
    & .radio,
    & .checkbox,
    & .radio-inline,
    & .checkbox-inline,
    &.radio label,
    &.checkbox label,
    &.radio-inline label,
    &.checkbox-inline label,
    & .form-control-feedback {
        color: $mol-green;
    }

    & .help-block {
        font-size: 9px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    & .form-control {
        border: 1px solid #e7e7e7;  
        border-right: 3px solid $mol-green;
        box-shadow: 0px -1px #fff, 0px -2px rgba(0,0,0, .4);

        &:focus {
            border: 1px solid #e7e7e7;  
            border-right: 3px solid $mol-green;
            box-shadow: 0px -1px #fff, 0px -2px rgba(0,0,0, .4);            
        }
    }
}

label small {
    display: block;
}

.field-info {
    position: absolute;
    top: 7px;
    right: -10px;
    width: 18px;
    height: 18px;
    background: #939598 url('/images/info.png') center center no-repeat;

    &.upload-info {
        right: -30px;
    }

    &:hover {
        background-color: $mol-green;
    }
}

.tooltip {
    top: 6px !important;
    width: 250px !important;

    & .tooltip-inner {
        max-width: 250px;
        text-align: left !important;
    }
}

.dropzone {
    margin-left: 20px;
    background: rgba(255,255,255, .4) !important;
    border: 2px dashed #939598 !important;
}
.post-content {
    position: relative;

    & .post {
        & .post-title, h2 {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0 0 30px;
            padding: 0;
        }  

        & h2 {
            margin-top: 5px;
        }

        & .post-body p {
            line-height: 24px;
            margin-bottom: 25px;
        } 

        & .news-title {
            margin: 0 0 10px;
            padding: 0;

            & h2 {
                margin: 0;
                padding: 0;
            }
        }

        & .news-meta {
            color: lighten($gray-light, 10%);
            margin-bottom: 30px;

            & i {
                margin-right: 5px;
                color: $gray-light;
            }
        }         
    }
}

@media (min-width: $screen-md-min) {
    & .post {
        width: 80%;
        margin: 0 auto;
    }

    & a.prev-link, & a.next-link {
        position: absolute;
        width: 68px;
        height: 114px;
        top: 60px;
    }

    & a.prev-link {
        background: url('/images/arrow-left.png') 0 0 no-repeat;
        left: -80px;

        &:hover {
            background-position: 0px -114px;
        }

        &:active {
            background-position: 0px -228px;
        }
    }

    & a.next-link {
        background: url('/images/arrow-right.png') 0 0 no-repeat;
        right: -80px;

        &:hover {
            background-position: 0px -114px;
        }

        &:active {
            background-position: 0px -228px;
        }        

    }    
}

.post-gallery {
    width: 90%;
    margin: 0 auto;
}
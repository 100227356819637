.header {
    margin-bottom: 30px;

    & h1 {
        font-size: 24px;
        margin: 0 0 10px 0;
        padding: 0;
        text-transform: uppercase;
        text-align: center;
    }

    &.home h1 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 30px;

        & span.red {
            display: block;
            color: $mol-red;
            font-size: 40px;
            font-weight: bold;
        }        
    }
}

.header-image {
    position: relative;
    height: 215px;
    background: url('/images/header1.png') no-repeat;
    background-size: cover;

    & .shape {
        background: url('/images/header-overlay.png') no-repeat;
        width: 52px;
        height: 215px;
    }

    & a.future-reloaded {
        position: absolute;
        top: 10px;
        right: 0;
        width: 109px;
        height: 49px;
        background: url('/images/jovo-ujratoltve.png') no-repeat;

        & span {
            display: none;
        }
    }

}


@media (min-width: $screen-sm-min) {
    .header {
        .quote {
            font-size: 18px;
            padding-left: 30px;
            margin-left: auto;
            margin-right: auto;
            width: 300px;

            &::before, &::after {
                display: inline-block;
                content: '“';
                font-family: "Times New Roman", Georgia, serif;
                font-weight: bold;
                font-size: 50px;
                color: #999898;
                float: left;
                width: 30px;
                margin-left: -30px;
                margin-top: -20px;
            }
            &::after {
                content: '”';
                float: right;
                margin-top: -90px;
                margin-right: 40px;
            }

            & span {
                display: block;
                text-align: right;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                margin-top: 30px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .header {

        h1 {
            line-height: 215px;
            font-size: 48px;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            text-align: left;
        }

        &.home h1 {
            margin-top: 80px;
            line-height: 1;
        }

        .quote {
            font-size: 18px;
            padding-left: 30px;
            float: left;
            width: auto;

            &::before, &::after {
                display: inline-block;
                content: '“';
                font-family: "Times New Roman", Georgia, serif;
                font-weight: bold;
                font-size: 50px;
                color: #999898;
                float: left;
                width: 30px;
                margin-left: -30px;
                margin-top: -20px;
            }
            &::after {
                content: '”';
                float: right;
                margin-top: -90px;
                margin-right: 40px;
            }

            & span {
                display: block;
                text-align: right;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                margin-top: 30px;
            }
        }
    }

    .header-image {
        &.home {
            height: 512px;
            background: url('/images/header1.png') no-repeat;

            & .shape {
                background: url('/images/header-overlay-home.png') no-repeat;
                width: 111px;
                height: 512px;
            }
        }
    }
}

@media (min-width: 1035px) {
    .header {

        &.home h1 {
            margin: 80px 0 40px;
            line-height: 1;
            font-size: 34px;

            & span.red {
                font-size: 52px;
            }
        } 
    }   
}
.article-grid, .media-tile {
    display: inline-block;
    margin: 0;

    & figure {
        position: relative;
        float: left;
        overflow: hidden;
        height: auto;
        text-align: center;
        cursor: pointer;

        & img {
            position: relative;
            display: block;
            height: 100%;
            max-height: 100%;
            width: 100%;
            opacity: 0.8;            
        }

        & figcaption {
            padding: 2em 0 0;
            color: #fff;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            &::before, &::after {
                pointer-events: none;
            }      

            & div {
                position: relative;
                z-index: 10;
            }      
        }

        & figcaption, & figcaption > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; 
            z-index: 50;           
        }

        & figcaption > a {
            z-index: 1000;
            text-indent: 200%;
            white-space: nowrap;
            font-size: 0;
            opacity: 1;            
        }
    }
}



figure.effect {
    background: $mol-gray;

    & img {
            opacity: 0.95;
            -webkit-transition: -webkit-transform 0.35s;
            transition: transform 0.35s;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
        }   

    & figcaption {
        transition: border-width .35s ease-in-out;
    }

    & h2 {
        position: absolute;
        bottom: 0;
        margin: 0;
        padding: 5px 0;
        width: 100%;
        font-size: 24px;
        text-align: left;
        text-transform: uppercase;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        pointer-events: none;

        & span {
            display: block;
            padding: 0 10px;
        }
    }

    & p {
       opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg);
        transform: perspective(1000px) rotate3d(1,0,0,90deg);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }    

    &:hover {
        & figcaption {
            border: 10px solid $mol-gray;
            transition: all .35s
        }

        & p {
            opacity: 1;
            -webkit-transform: perspective(1000px) rotate3d(1,0,0,0);
            transform: perspective(1000px) rotate3d(1,0,0,0);            
        }
    }        
}

.article-grid:nth-child(2n-1), .article-grid:nth-child(4n) {
    & h2 {
        background: #609a5b;
        color: #fff;
    }

    &:hover {
        figcaption {
            background: rgba(96, 154, 91, 0.6);
        }
    }    
}

.article-grid:nth-child(3n-1) {
    & h2 {
        background: #70b46a;
        color: #fff;
    }

    &:hover {
        & figcaption {
            background: rgba(112, 180, 106, .6);
        }
    }
}

.article-grid:nth-child(3n) {
    & h2 {
        background: #8dd287;
        color: #fff;
    }

    &:hover {
        figcaption {
            background: rgba(141, 210, 135, 0.6);
        }
    }
}

.media-tile {
    margin-bottom: 0;

    & figure {
        float: none;

        & figcaption {
            padding: 0;

            & div {
                opacity: 0;
            }

            & h2 {
                position: static;
                padding: 0 25px;
                font-size: 30px;
                font-weight: bold;
                pointer-events: none;
            }

            & p {
                text-transform: uppercase;
                text-align: left; 
                opacity: 1;  
                padding: 0 25px;
                -webkit-transform: perspective(1000px) rotate3d(1,0,0,0);
                transform: perspective(1000px) rotate3d(1,0,0,0);  
                font-size: 24px;
            }
        }
    }   
}

.yellow {
    color: #fff;
    background: rgba(248, 169, 0, .7) !important;
}

.purple {
    color: #fff;
    background: rgba(147, 96, 164, .7) !important;
}

.dark-purple {
    color: #fff;
    background: rgba(39, 53, 131, .7) !important;
}

.green {
    color: #fff;
    background: rgba(162, 206, 104, .7) !important;    
}

.brown {
    color: #fff;
    background: rgba(149, 119, 96, .7) !important;
}

.gallery {
    color: #fff;
    background: transparent;

    &:hover {
        color: #fff;
        background: rgba(162, 206, 104, .7) !important;  
    }
}

@media (min-width: $screen-md-min) {
    .article-grid {
        margin-bottom: 20px;
    }
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 70px;
    padding-top: 10px;
    border-bottom: 4px solid $mol-green;
    text-align: center;
    overflow: hidden;

    & ul {
        list-style: none;
        text-align: center;
        padding-start: 0;
        -webkit-padding-start: 0;

        & li {
            display: inline-block;
            padding: 0 15px;

            & a {
                color: #999898;

                &:hover, &:active {
                    color: darken(#999898, 10%);
                    text-decoration: none;
                }
            }
        }
    }
    
}

@media (min-width: $screen-md-min) {
    .footer {
        height: 60px;
        padding-top: 20px;
    }
}
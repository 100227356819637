.countdown {
    background: $mol-red;
    color: #fff;
    text-align: center;
    padding: 15px 0 10px;

    .clock {
        width: 80%;
        margin: 0 auto 25px;

        & span.cd-box {
            display: inline-block;
            width: 33.3333%;

            & span {
                display: block;
                text-align: center;

                &.cd-value {
                    font-size: 46px;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .countdown {
        min-height: 205px;
    }
}
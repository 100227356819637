.navbar-default {
    margin-top: 20px;
    background: inherit;
    border: none;

    & .navbar-toggle {
        background: #fff;
        margin-top: 30px;
        margin-right: 0px;
    }

    & .navbar-nav > .active > a,
    & .navbar-nav > .active > a:hover,
    & .navbar-nav > .active > a:focus,
    & .navbar-nav > .open > a,
    & .navbar-nav > .open > a:hover,
    & .navbar-nav > .open > a:focus,
    & .navbar-nav > .dropdown:hover > a {
        background: $mol-green;
        color: #fff;
    }

    & .navbar-brand {
        display: inline-block;
        width: 266px;
        height: 38px;
        background: url('/images/mol-logo.png') center center no-repeat;
    }

    & .navbar-brand-ne {
        float: none;
        text-indent: -9999pt;
        display: inline-block;
        width: 123px;
        height: 38px;
        background: url('/images/ok-38.png') center center no-repeat;
    }
}

@media (min-width: $screen-sm-min) {
    .navbar-default {
        & .navbar-toggle {
            margin-top: 0;
        }

        .navbar-brand-ne {
            text-indent: -9999pt;
            display: inline-block;
            width: 123px;
            height: 38px;
            margin-top: 0;
        }
    }
}

@media (min-width: $screen-md-min) {
    .navbar-default {
        padding-top: 5px;
        background: url('/images/nav-bg.png') top right no-repeat;

        &  .navbar-brand-ne {
            float: right;
            text-indent: -9999pt;
            display: inline-block;
            width: 123px;
            height: 38px;
            background-position: 0px 0px;
        }
    }

    .navbar-header {
        width: 390px;
    }

    .navbar-nav {
        margin-top: 5px;
    }

    .navbar-nav > li.dropdown {
        height: 38px;

        &:hover > ul.dropdown-menu {
            display: block;
        }
    }

    & .navbar-nav > .login > a,
    & .navbar-nav > .dropdown.login:hover > a {
        background: #939598;
        color: #fff !important;
    }

    .navbar-nav > li > a {
        padding: 5px 18px;
    }

    .dropdown-menu.pull-center {
        right: 50%;
        left: auto;
        background: rgba(162, 206, 104, .9);
        border: none;

        &::before {
            left: auto;
            right: 50%;
            margin-right: -7px;
            width: 0;
            height: 0;
            content: '';
            display: inline-block;
            position: absolute;
            border-color: transparent;
            border-style: solid;
            -webkit-transform: rotate(360deg);
            border-width: 0 7px 7px;
            border-bottom-color: rgba(162, 206, 104, .9);
            //border-bottom-color: rgba(, 0.15);
            top: -7px;
        }

        &.login {
            background: rgba(255,255,255, .8);

            &::before {
                border-bottom-color: rgba(255,255,255, .8);
            }
        }

        & > li > a {
            background-color: inherit;
            color: #fff;

            &:hover, &:active {
                background-color: $mol-green;
            }
        }
    }

    .dropdown-menu.login {
        right: 0%;
        left: auto;
        background: rgba(255,255,255, .95);
        border: none;
        min-width: 170px;

        &::before {
            left: auto;
            right: 0;
            margin-right: 7px;
            width: 0;
            height: 0;
            content: '';
            display: inline-block;
            position: absolute;
            border-color: transparent;
            border-style: solid;
            -webkit-transform: rotate(360deg);
            border-width: 0 7px 7px;
            border-bottom-color: rgba(255,255,255, .95);
            top: -7px;
        }

        & a.lost-password {
            text-transform: lowercase;
            color: #008663;
            text-decoration: underline;

            &:hover, &:active {
                color: ligthen(#008663, 5%);
            }
        }
    }
}

@media (min-width: $screen-tablet-max) and (max-width: $screen-lg-min) {
    .navbar-default {
        & .navbar-header {
            width: auto;
        }
        &  .navbar-brand {
            width: 200px;
            height: 30px;
            background: transparent url('/images/mol-logo-sm.png');
            background-position: center center
        }

        &  .navbar-brand-ne {
            float: right;
            text-indent: -9999pt;
            display: inline-block;
            width: 100px;
            height: 27px;
            margin-left: 20px;
            background: url('/images/uj-europa-sm.png');
            background-position: 0px 0px;
        }
    }
}

.nav-pills > li {
    & a {
        color: #fff;
        background: $mol-green;
    }

    &:hover a, &.active a, &.active a:hover, &.active:focus {
        background-color: $mol-red;
    }
}

.panel-green {
    border: none;
    background: transparent;
    margin-bottom: -1px;

    .panel-heading {
        background: $mol-green;
        color: #fff;
        text-transform: uppercase;
        font-size: 24px;

        & a {
            text-transform: none;
            display: block;

            &.faq-title-closed {
                padding-right: 40px;
                min-height: 25px;
                background: url('/images/faq-closed.png') center right no-repeat;
            }
            &.faq-title-opened {
                padding-right: 40px;
                min-height: 25px;                
                background: url('/images/faq-opened.png') center right no-repeat;
            }            

            &:hover, &:visited, &:focus {
                text-decoration: none;
            }
        }
    }

    .panel-body {
        background: #fff;

        &.panel-body-transparent {
            background: transparent !important;
            padding-left: 35px;
            padding-right: 35px;
            border: none;
        }
    }    

    .panel-footer {
        border: none;
        background: #fff;
    }
}

.panel-red {
    border: none;
    background: transparent;

    .panel-heading {
        background: $mol-red;
        color: #fff;
        text-transform: uppercase;
        font-size: 24px;

        & a {
            text-transform: none;
            display: block;

            &.faq-title-closed {
                padding-right: 40px;
                min-height: 25px;
                background: url('/images/faq-closed.png') center right no-repeat;
            }
            &.faq-title-opened {
                padding-right: 40px;
                min-height: 25px;
                background: url('/images/faq-opened.png') center right no-repeat;
            }               

            &:hover, &:visited, &:focus {
                text-decoration: none;
            }
        }
    }

    .panel-body {
        &.panel-body-transparent {
            background: transparent !important;
            padding-left: 35px;
            padding-right: 35px;
            border: none;
        }
    }

    .panel-footer {
        border: none;
        background: #fff;
    }
}

@media (min-width: $screen-md-min) {
    .panel-green, .panel-red {
        & .panel-heading {
            & a.faq-title-opened, & a.faq-title-closed {
                line-height: 25px;
            }
        }
    }
    .panel-green{
        & .panel-body {
            min-height: 109px;
        }
    }
}

.countdown {
    margin-bottom: -1px;
}


.separator {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid lighten(#939598, 20%);
}


@for $i from 1 through 10{
  $step : $i*1;
  .p-t-#{$step} {
    padding-top: ($step*1px) !important;
  }
  .p-r-#{$step} {
    padding-right: ($step*1px) !important;
  }
  .p-l-#{$step} {
    padding-left: ($step*1px) !important;
  }
  .p-b-#{$step} {
    padding-bottom: ($step*1px) !important;
  }
  .padding-#{$step} {
    padding: ($step*1px) !important;
  }
}

/**
 * Paddings
 */
@for $i from 0 through 20{
  $step : $i*5;
  .p-t-#{$step} {
    padding-top: ($step*1px) !important;
  }
  .p-r-#{$step} {
    padding-right: ($step*1px) !important;
  }
  .p-l-#{$step} {
    padding-left: ($step*1px) !important;
  }
  .p-b-#{$step} {
    padding-bottom: ($step*1px) !important;
  }
  .padding-#{$step} {
    padding: ($step*1px) !important;
  }
}

/**
 * Margins
 */
@for $i from 0 through 20{
  $step : $i*5;
  .m-t-#{$step} {
    margin-top: ($step*1px) !important;
  }
  .m-r-#{$step} {
    margin-right: ($step*1px) !important;
  }
  .m-l-#{$step} {
    margin-left: ($step*1px) !important;
  }
  .m-b-#{$step} {
    margin-bottom: ($step*1px) !important;
  }
}

@for $i from 0 through 20{
  $step : $i*5;
  .w-#{$step} {
    width: ($step*1%);
  }
}

@for $i from 0 through 200{
  $step : $i*5;
  .w-#{$step}-px {
    width: ($step*1px);
  }
}

@for $i from 10 through 48{
  $step : $i;
  .f-s-#{$step} {
    font-size: ($step*1px) !important;
  }
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}